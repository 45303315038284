import React from "react";
import { useState, useEffect, useContext } from "react";
import ProjectDetails from "./ProjectDetails";
import PersonalDetails from "./PersonalDetails";
import PrevGrantDetails from "./PrevGrantDetails";
import Success from "./Success";
import axios from "axios";
import ApplyInfo from "./ApplyInfo";
import { ApplyContext } from "../context/applyContext";
import { AuthContext } from "../context/authContext";

import ApplicationNavbar from "../components/ApplicationNavbar";
import { useNavigate, useLocation } from "react-router-dom";

const Write2 = () => {
  const location = useLocation();
  const [site, setSite] = useState({
    step: parseInt(location.pathname.split("/")[2]),
  });
  const { currentUser } = useContext(AuthContext) || null;
  const { applications, setState } = useContext(ApplyContext);

  const [myApplication, setMyApplication] = useState(
    {
      uid: currentUser.id,
      coauthor: [],
    } || ""
  );
  const [coauthor] = useState([]);
  const navigate = useNavigate();

  const date = new Date()
  const year = date.getFullYear()

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/posts/year/${year}`
      );
      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        return message;
      }
      const posts = await response.json();
      const uidPosts = posts?.filter((e) => e.uid == currentUser.id)
      console.log(uidPosts[0])
      setMyApplication(uidPosts[0]);
    }
    fetchData();
  }, [currentUser, applications]);

  const handIn = async () => {
    myApplication.completed = true;
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/posts`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(myApplication),
    });
    await response.json();
    if (response.status === 201) {
      navigate("/submitted");
    } else {
      console.log("error");
    }
  };

  const prevStep = () => {
    const { step } = site;
    postStateToDb();
    setSite({ step: step - 1 });
    navigate(`/application/${parseInt(location.pathname.split("/")[2]) - 1}`);
  };

  const nextStep = () => {
    let { step } = site;
    postStateToDb();
    setSite({ step: step + 1 });
    navigate(`/application/${parseInt(location.pathname.split("/")[2]) + 1}`);
  };
  const setStep = (input) => {
    let step2 = parseInt(input);
    postStateToDb();
    setSite({ step: step2 });
    navigate(`/application/${step2}`);
  };

  const handleChange = (input) => (e) => {
    setMyApplication((prev) => ({ ...prev, [input]: e.target.value }));
  };
  const handleListChange = async (formData, from) => {
    if (from === "coauthor") {
      myApplication.coauthor.push(formData);
    } else if (from === "publications") {
      myApplication.publications.push(formData);
    } else {
      myApplication.prevgrants.push(formData);
    }
    //setCoauthor([...coauthor, { title: { title }, name: { name } }]);
  };
  const handleListRemove = async (input, from) => {
    if (from === "coauthor") {
      myApplication.coauthor = input;
    } else if (from === "publications") {
      myApplication.publications = input;
    } else {
      myApplication.prevgrants = input;
    }
  };

  const handleChangeFile = async (input, name) => {
    setMyApplication((prev) => ({ ...prev, [input]: name }));
  };

  const postStateToDb = async () => {
    console.log(myApplication)
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/posts`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(myApplication),
    });
    await response.json();
  };

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", myApplication.cv);
      const res = await axios.post("/upload", formData);

      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
  const handleFile = async (e) => {
    e.preventDefault();
    let imgUrl = "";
    if (myApplication.cv) {
      imgUrl = await upload();
      myApplication.cv = imgUrl;
    }
  };

  const { step } = site;

  switch (step) {
    case 1:
      return (
        <ApplyInfo
          nextStep={nextStep}
          handleChange={handleChange}
          prevStep={prevStep}
        />
      );
    case 2:
      return (
        //TO DO change title to ContactDetails
        <>
          <ApplicationNavbar setStep={setStep} site={site} />
          <PersonalDetails
            nextStep={nextStep}
            handleChange={handleChange}
            prevStep={prevStep}
            values={myApplication}
            handleChangeFile={handleChangeFile}
            postStateToDb={postStateToDb}
            coauthor={coauthor}
            setState={setState}
            handleListChange={handleListChange}
            handleListRemove={handleListRemove}
          />
        </>
      );
    case 3:
      return (
        //TO DO change title to ProjectDetails
        <>
          <ApplicationNavbar setStep={setStep} site={site} />
          <ProjectDetails
            nextStep={nextStep}
            handleChange={handleChange}
            prevStep={prevStep}
            values={myApplication}
            handleChangeFile={handleChangeFile}
            handleFile={handleFile}
            postStateToDb={postStateToDb}
            handleListChange={handleListChange}
            handleListRemove={handleListRemove}
            setState={setState}
          />
        </>
      );

    case 4:
      return (
        <>
          <ApplicationNavbar setStep={setStep} site={site} />
          <PrevGrantDetails
            // TO DO change title to PrevGrantDetails
            nextStep={nextStep}
            handleChange={handleChange}
            prevStep={prevStep}
            values={myApplication}
            postStateToDb={postStateToDb}
            setState={setState}
            handleListChange={handleListChange}
            handleListRemove={handleListRemove}
          />
        </>
      );
    case 5:
      return (
        <>
          <ApplicationNavbar setStep={setStep} site={site} />
          <Success
            // TO DO change title to ConfirmDetails
            nextStep={nextStep}
            handleChange={handleChange}
            prevStep={prevStep}
            values={myApplication}
            postStateToDb={postStateToDb}
            handIn={handIn}
          />
        </>
      );
    // never forget the default case, otherwise VS code would be mad!
    default:
    // do nothing
  }
};

export default Write2;
