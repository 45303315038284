import { React, useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../components/Table";
import { AuthContext } from "../../context/authContext";
import AllPdfDocument from "../../components/AllPdfDocument";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import Table2 from "../../components/DragableTable/Table";
//import Table2 from "../../components/DragableTable/Dragable2"

function Admin() {
  const { currentUser, stage } = useContext(AuthContext);
  //const { applications } = useContext(AuthContext);
  const [applications, setApplications] = useState() || null;
  const [grades, setGrades] = useState() || null;
  const [show, setShow] = useState() || null;
  const [currentUserReady, setCurrentUserReady] = useState() || null;
  const [shownApplication, setShownApplication] = useState();
  const [approvedApplications, setApprovedApplications] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser && currentUser?.role == "user") {
      navigate("/");
    }
    if (currentUser && stage) {
      setCurrentUserReady(true);
    }
  }, [currentUser]);
  const updateState = (e) => {
    setApplications(e);
  };

  const today = new Date();
  const month = today.getMonth() + 1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let year = today.getFullYear();
        if (month > 7) {
          year = today.getFullYear() + 1;
        }
        let response;
        let res2;
        Promise.all([
          (response = await fetch(`${process.env.REACT_APP_BASE_URL}/posts/`)),
          (res2 = await fetch(`${process.env.REACT_APP_BASE_URL}/stage/`)),
        ]);

        if (!response.ok) {
          const message = `An error occurred: ${response.statusText}`;
          return;
        }
        let posts;
        let response2;
        Promise.all([
          (posts = await response.json()),
          (response2 = await res2.json()),
        ]);

        // posts.myfinalgrade = posts.finalgrades.grade;
        const thisYearPosts = posts.filter((e) => e.year == year);
        const completedPosts = thisYearPosts.filter(
          (e) => e.completed === true
        );
        const approvedPosts = completedPosts.filter((e) => e.approved === true);
        const myPosts = approvedPosts.filter(
          (e) => e.scorer === currentUser?.id
        );
        const finalists = approvedPosts.filter((e) => e.finalist === true);
        finalists.map((e) => {
          e.finalgrades.map((element) => {
            if (element.user == currentUser?.id) {
              e.myfinalgrade = element.grade;
            }
          });
        });
        finalists.map((e) => {
          e.grades.map((element) => {
            if (element.user == currentUser?.id) {
              e.mygrade = element.grade;
            }
          });
        });
        setApplications(completedPosts);
        if (response2.stage == 1) {
          setApprovedApplications(approvedPosts);
        } else if (response2.stage == 3) {
          setApprovedApplications(finalists);
        } else {
          setApprovedApplications(myPosts);
        }

        setShow("false");
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [currentUserReady]);

  useEffect(() => {
    if (show === "false") {
      setShownApplication(approvedApplications);
    } else {
      setShownApplication(applications);
    }
  }, [show]);

  const handleChange = (e) => {
    if (show === "true") {
      setShow("false");
    } else {
      setShow("true");
    }
  };
  let columns2 = [
    {
      label: "Namn",
      accessor: "name",
      sortable: false,
    },
    { label: "Titel", accessor: "title", sortable: false },

    { label: "Universitet", accessor: "university", sortable: false },
    { label: "Datum", accessor: "createdAt", sortable: false },
    {
      label: "Mitt betyg",
      accessor: "mygrade",
      sortable: false,
    },
    {
      label: "Ranking",
      accessor: "myfinalgrade",
      sortable: false,
      sortbyOrder: "asc",
    },
  ];
  let columns = [];
  if (stage === "3") {
    columns = [
      // { label: "Id", accessor: "id", sortable: true, sortbyOrder: "asc" },
      {
        label: "Namn",
        accessor: "name",
        sortable: false,
      },
      { label: "Titel", accessor: "title", sortable: false },

      { label: "Universitet", accessor: "university", sortable: false },
      { label: "Datum", accessor: "createdAt", sortable: false },
      {
        label: "Slutbetyg",
        accessor: "finalgrades",
        sortable: false,
        sortbyOrder: "asc",
      },
    ];
  } else if (stage === "1") {
    columns = [
      {
        label: "Namn",
        accessor: "name",
        sortable: true,
      },
      { label: "Titel", accessor: "title", sortable: true },

      { label: "Universitet", accessor: "university", sortable: true },
      { label: "Datum", accessor: "createdAt", sortable: true },
      { label: "Jäv", accessor: "bias", sortable: false },
    ];
  } else {
    columns = [
      // { label: "Id", accessor: "id", sortable: true, sortbyOrder: "asc" },
      {
        label: "Namn",
        accessor: "name",
        sortable: true,
      },
      { label: "Titel", accessor: "title", sortable: true },
      // {
      //   label: 'Beskrivning',
      //   accessor: 'desc',
      //   sortable: true,
      //   sortbyOrder: 'desc',
      // },
      { label: "Universitet", accessor: "university", sortable: true },
      { label: "Datum", accessor: "createdAt", sortable: true },
      { label: "Mitt betyg", accessor: "grade", sortable: true },
      { label: "Finalist", accessor: "finalist", sortable: true },
    ];
  }

  return (
    // <div>
    //   Adminsida <br />
    //   <br />
    //   <table>
    //     <tr key={'header'}>
    //       {Object.keys(applications[0]).map((key) => (
    //         <th>{key}</th>
    //       ))}
    //     </tr>
    //     {applications.map((application) => (
    //       <tr key={application.id}>
    //         {Object.values(application).map((val) => (
    //           <td>{val}</td>
    //         ))}
    //         <Link
    //           className='read-more-button'
    //           key={application.id}
    //           to={`/post/${application.id}`}
    //         >
    //           Se mer
    //         </Link>
    //       </tr>

    <div className="table_container">
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
            flexDirection: "column",
          }}
        >
          {" "}
          <div
            className="adminprocess"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <PDFDownloadLink
              style={{
                textDecoration: "underline",
                color: "black",
                marginRight: "2rem",
              }}
              document={<AllPdfDocument values={approvedApplications} />}
              fileName="AnsökanHedberg.pdf"
            >
              {({ _blob, _url, loading, _error }) =>
                loading ? "Loading document..." : "Ladda ner pdf av samtliga"
              }
            </PDFDownloadLink>

            <Link className="link">
              <button style={{ marginRight: "1rem" }}>Betygsätt</button>
            </Link>
            {currentUser?.role == "admin" && (
              <Link className="link" to={"/adminprocess"}>
                <button>Administrera betygsprocess</button>
              </Link>
            )}
            {currentUser?.role == "superadmin" && (
              <Link className="link" to={"/adminprocess"}>
                <button>Administrera betygsprocess</button>
              </Link>
            )}
            <Link className="link" to={"/adminranking"}>
              <button style={{ marginLeft: "1rem" }}>Se slutlig ranking</button>
            </Link>
          </div>
          <h1>Adminsida</h1>
        </div>
        <label style={{ marginRight: "2vh" }}>Visa alla ansökningar</label>
        <input onChange={handleChange} type="checkbox" />
        {stage == 2 ? (
          <>
            <p style={{ marginTop: "10px", fontSize: "20px" }}>
              Antal finalister:{" "}
              {shownApplication?.filter((e) => e.finalist == true).length}
            </p>
          </>
        ) : (
          <></>
        )}{" "}
        {shownApplication ? (
          <>
            {stage == "3" ? (
              <Table2
                caption="Ansökningar"
                data={shownApplication}
                columns={columns2}
                updateState={updateState}
                stage={stage}
              />
            ) : (
              <Table
                caption="Ansökningar"
                data={shownApplication}
                columns={columns}
                updateState={updateState}
                stage={stage}
              />
            )}
          </>
        ) : (
          <h1>Laddar...</h1>
        )}
      </>
    </div>
  );
}
// <Link
//   key={application.id}
//   className='link'
//   to={`/post/${application.id}`}
// >
//   <h1>
//     {application.title} {application.firstname}
//   </h1>
// </Link>
//     ))}
//   </table>
// </div>
//   );
// }

export default Admin;
